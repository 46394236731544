import * as React from "react"
import {useEffect, useState} from "react";
import {Helmet} from "react-helmet";
import Menu from "../components/menu"
import "../css/main.scss"
import StandardHeadContent from "../components/standardHeadContent";
import Cookies from "universal-cookie";
import Axios from "axios";

const MarathonPage = () => {
    const cookies = new Cookies();
    const [lang,setLang] = useState(cookies.get("lang") ||"no");

    const changeLang = (lang) => {
        cookies.set("lang",lang);
        setLang(lang);
    }
    useEffect(() => {
        Axios.get("/log.php?page=marathon_"+lang);
    },[])


    return (
        <main>
            <Helmet>
                <title>Nina & Knut - 11. juli 2022</title>
                <StandardHeadContent/>
            </Helmet>

            <Menu lang={lang} changeLang={changeLang}/>

            <div id="duogram"></div>

            {lang != "en" &&
                <>
                    <h1>Ting å gjøre utenom bryllupet</h1>

                    <h2>
                        Moraitis Sports Center
                    </h2>
                    <p>
                    Nyt sola og stranda på Moraitis Beach i Schinias, 50 meter unna der bryllupet skal finne sted. Her kan du både late deg på solsenga med en drink i hver hånd (også kjent som Nina style), eller du kan være aktiv og windsurfe (Knut style). Om du ikke kan windsurfe er det mulig å bestille timer for å lære seg det, eller utforske det øvrige tilbudet i vannsportssortimentet deres. Møt opp innen kl. 10 om du skal være noenlunde sikret brukbart valg av plassering på solsenga. Ranket som nr.10 på USA today’s liste over de beste strendene i Athen.</p>

                    <h2>Galazia Akti</h2>
                    <p>
                        Stedet hvor bryllupet skal foregå er også beach club på dagtid. Dersom du ikke er spesielt interessert i vannsport, er dette stedet ranket som nr. 6 på USA today’s liste over de beste strendene i Athen.
                    </p>

                    <h2>Athen by</h2>
                    <p>
                        Athen har mye å by på, men husk at det kommer til å være «bra varmt». Dersom du vil tilbringe en dag i Athen er vårt forslag å ta deler av shoppingen inne på kjøpesenter når sola steker som verst, eventuelt museumsbesøk eller lignende. Air conditioning is key. Alternativt ta turen inn når sola er avtagende.
                    </p>


                </>
            }
            {lang == "en" &&
                <>
                    <h1>Things to do in the area</h1>

                    <p>This page has not yet been translated to English.</p>

                </>
            }

        </main>
    )
}

export default MarathonPage
